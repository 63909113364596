



















































































































import {Component, Mixins} from "vue-property-decorator";
import UserMixin from '@f/mixins/UserMixin'
import HandleImpersonate from '@f/mixins/HandleImpersonate'
import ShowMenu from '@f/mixins/ShowMenu'
import Modal from "@f/components/Modal.vue";
import Popover from "@f/components/Popover.vue";
import PopCounter from "@f/components/PopCounter.vue";
import Icon from '@f/components/Icon.vue'
import Treecon from '@f/components/Treecons/Treecon.vue'
import {NOTIFICATION_LIST} from '@f/graphql/queries'
import {TrNotificationCount, TrNotificationListQuery, TrPageNotification} from '@f/@types/graphql'
import {NOTIFICATION_SET_ALL_AS_READ} from "@f/graphql/mutations";
import Overlay from '@f/components/Overlay.vue'
import DotLoader from '@f/components/DotLoader.vue'

@Component({
  name: "UserWidget",
  components: {
    Overlay,
    DotLoader,
    Modal,
    Popover,
    PopCounter,
    Icon,
    Treecon,
    NotificationsList: () => import( /* webpackChunkName: 'notifications-list' */ '@f/components/Notifications/NotificationsList.vue'),
  },
  apollo: {
    notificationList: {
      query: NOTIFICATION_LIST,
      deep: false,
      variables() {
        return {
          offset: 0,
          limit: 10,
          userId: this.userInfo?.id,
        }
      },
      skip: true,
    }
  }
})
export default class UserWidget extends Mixins(UserMixin, ShowMenu, HandleImpersonate) {
  loading = false
  openPopover: boolean = false
  showNotifications: boolean = false
  notificationsCount: TrNotificationCount = {}
  notificationList: TrPageNotification = {
    edges: [],
  }

  /*--- COMPUTED ------*/
  get cartUrl(): string {
    return 'https://mc.europe-west1.gcp.commercetools.com/';
  }

  get hasMoreNotifications() {
    return !!this.notificationList.pageInfo?.hasNextPage;
  }

  /*--- METHODS -------*/

  openNotifications(): void {
    this.openPopover = false
    this.showNotifications = true
  }

  fetchMoreNotifications() {
    if (!this.notificationList.pageInfo?.hasNextPage) return;
    if (this.$apollo.queries.notificationList.loading) return;
    return this.$apollo.queries.notificationList.fetchMore({
      variables: {
        userId: this.userInfo?.id,
        after: this.notificationList.pageInfo?.endCursor,
        limit: 10,
      },
      updateQuery: (previousResult: TrNotificationListQuery, { fetchMoreResult }: { fetchMoreResult: TrNotificationListQuery  }) => {
        // No new feed posts
        if (!fetchMoreResult) return previousResult
        // Concat previous feed with new feed posts
        return {
          notificationList: {
            ...fetchMoreResult.notificationList,
            edges: [
              ...previousResult.notificationList!.edges,
              ...fetchMoreResult.notificationList!.edges,
            ],
          },
        }
      },
    })
  }

  resetNotificationList() {
    this.notificationList = {
      edges: [],
    }
  }

  async setNotificationsAsReaded() {
    if (this.notificationsCount.notNotified) {
      await this.$apollo.mutate({
        mutation: NOTIFICATION_SET_ALL_AS_READ,
        variables: {
          userId: this.userInfo?.id,
        },
      })
      this.resetNotificationList()
    }
  }

  async fetchNotificationAndSetAllAsRead() {
    await this.setNotificationsAsReaded()
  }

  openGiftComposer(ev: UIEvent): void {
    this.$rootScope.openGiftComposerExternal('treedom-navbar-user-menu')
  }
}
